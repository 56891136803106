<!-- eslint-disable max-len -->
<template>
  <div class="Start content">
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 351.946 279.118"
      >
        <defs>

          <clipPath id="screen">
            <rect width="168" height="87" />
          </clipPath>

          <filter
            id="Rectangle_12"
            x="44.585"
            y="0"
            width="268.235"
            height="175.588"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="5" result="blur" />
            <feFlood flood-color="#0a122b" flood-opacity="0.149" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>

          <filter
            id="Rectangle_21"
            x="54.115"
            y="189.118"
            width="245.41"
            height="90"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="5" result="blur-2" />
            <feFlood flood-color="#0a122b" flood-opacity="0.149" />
            <feComposite operator="in" in2="blur-2" />
            <feComposite in="SourceGraphic" />
          </filter>

        </defs>
        <g id="Slot" transform="translate(24.585 -295)">
          <path
            id="Path_29"
            data-name="Path 29"
            d="M31.04,14.684s.485-11.671,0,16.832S67.051,82.56,58.6,97.686,33.379,98.812-1.1,93.569c-12.911-1.964-13.78,1.389-34.1,4.117-25.566,3.432-47.264-3.924-67.465-4.117-36.721-.351-69.556,3.256-122.566,0-64.754-4.974-61.2,18.512-51.279,26.992s23.924,4.991,23.949,22.2,0,24.592,0,24.592"
            transform="translate(262.5 358.912)"
            fill="none"
            stroke="#6da4e2"
            stroke-linejoin="round"
            stroke-width="10"
          />
          <g id="Group_3" data-name="Group 3" transform="translate(32 307)">
            <rect
              id="Rectangle_20"
              data-name="Rectangle 20"
              width="208.235"
              height="27.353"
              rx="3"
              transform="translate(15 138.529)"
              fill="#002943"
            />
            <rect
              id="Rectangle_19"
              data-name="Rectangle 19"
              width="32.647"
              height="30.882"
              rx="3"
              transform="translate(241.765 57.353)"
              fill="#002943"
            />
            <rect
              id="Rectangle_18"
              data-name="Rectangle 18"
              width="20.294"
              height="51.176"
              rx="3"
              transform="translate(229.412 47.647)"
              fill="#003A60"
            />
            <g
              transform="matrix(1, 0, 0, 1, -56.59, -12)"
              filter="url(#Rectangle_12)"
            >
              <rect
                id="Rectangle_12-2"
                data-name="Rectangle 12"
                width="238.235"
                height="145.588"
                rx="12"
                transform="translate(56.59 12)"
                fill="#a7c8ee"
              />
            </g>
            <rect
              id="Rectangle_13"
              data-name="Rectangle 13"
              width="215.294"
              height="123.529"
              rx="6"
              transform="translate(11.471 11.471)"
              fill="#6da4e2"
            />
            <rect
              id="Rectangle_14"
              data-name="Rectangle 14"
              width="194.118"
              height="102.353"
              rx="3"
              transform="translate(22.059 22.059)"
              fill="#002943"
            />
            <rect
              id="Rectangle_15"
              data-name="Rectangle 15"
              width="48.529"
              height="88.235"
              rx="3"
              transform="translate(34.412 29.118)"
              fill="#f0eeec"
            />
            <rect
              id="Rectangle_16"
              data-name="Rectangle 16"
              width="48.529"
              height="88.235"
              rx="3"
              transform="translate(155.294 29.118)"
              fill="#f0eeec"
            />
            <rect
              id="Rectangle_17"
              data-name="Rectangle 17"
              width="48.529"
              height="88.235"
              rx="3"
              transform="translate(95.294 29.118)"
              fill="#f0eeec"
            />

            <g
              id="spools"
              class="box"
              transform="translate(35 30)"
              clip-path="url(#screen)"
            >
              <g class="spool one" transform="translate(23.5 69)">>
                <text v-for="n in 10" :key="n" text-anchor="middle">{{ n - 1 }}</text>
              </g>
              <g class="spool two" transform="translate(84.5 69)">
                <text v-for="n in 10" :key="n" text-anchor="middle">{{ n - 1 }}</text>
              </g>
              <g class="spool three" transform="translate(144.5 69)">
                <text v-for="n in 10" :key="n" text-anchor="middle">{{ n - 1 }}</text>
              </g>
            </g>

          </g>
          <g
            id="Group_4"
            data-name="Group 4"
            transform="translate(-2.588 485.118)"
            @click="spin()"
          >
            <rect
              id="Rectangle_23"
              data-name="Rectangle 23"
              width="32.647"
              height="30.882"
              rx="3"
              transform="translate(0 25.706)"
              fill="#002943"
            />
            <rect
              id="Rectangle_24"
              data-name="Rectangle 24"
              width="20.294"
              height="51.176"
              rx="3"
              transform="translate(24.706 16)"
              fill="#003A60"
            />
            <rect
              id="Rectangle_22"
              data-name="Rectangle 22"
              width="238"
              height="84"
              rx="12"
              transform="translate(33.529)"
              fill="#6da4e2"
            />
            <g
              transform="matrix(1, 0, 0, 1, -22, -190.12)"
              filter="url(#Rectangle_21)"
            >
              <rect
                id="Rectangle_21-2"
                data-name="Rectangle 21"
                width="215.41"
                height="60"
                rx="6"
                transform="translate(66.11 201.12)"
                fill="#a7c8ee"
              />
            </g>
            <text
              id="SPIN"
              transform="translate(151.412 49.5)"
              fill="#f0eeec"
              font-size="26"
              font-weight="700"
            >
              <transition name="appear">
                <tspan v-if="!ran" x="-29.663" y="0">SPIN</tspan>
              </transition>
              <transition name="appear">
                <tspan v-if="ran&&code" x="-29.663" y="0">{{ code }}</tspan>
              </transition>
            </text>
          </g>
        </g>
      </svg>
    </div>

    <transition name="appear">
      <div v-if="popup" class="backdrop" @click="popup = false" />
    </transition>

    <div class="text-center mt-8">
      <h1>{{ $t("start.title") }}</h1>
      <p class="mt-4">
        {{ $t("start.subtitle") }}
      </p>
      <go :to="`https://win.velocorner.ch/register?lang=${$i18n.locale}`" class="button mt-6">
        {{ $t("start.action") }}
      </go>
    </div>
  </div>
</template>

<script>
const API = `${window.location.protocol}//${window.location.hostname}`;

const sectionHeight = 100;
const sections = 10;
const totalHeight = sectionHeight * sections;

const mod = (n, m) => ((n % m) + m) % m;
const easeInOutCubic = (x) => (x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2);

function animate({ timing, draw, duration }) {
  return new Promise((resolve) => {
    const start = performance.now();
    requestAnimationFrame(function _animate(time) {
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;

      const progress = timing ? timing(timeFraction) : timeFraction;

      if (draw) draw(progress);

      if (timeFraction < 1) {
        requestAnimationFrame(_animate);
      } else {
        resolve();
      }
    });
  });
}

export default {
  data: () => ({
    popup: false,
    ran: false,
    code: '',
  }),
  mounted() {
    const { lang } = this.$route.query;
    if (lang) this.$i18n.locale = lang;
    this.spools = [
      document.querySelectorAll('.spool.one text'),
      document.querySelectorAll('.spool.two text'),
      document.querySelectorAll('.spool.three text'),
    ];
    this.offset = [0, 0, 0];

    this.running = true;
    this.updateLoop();
  },
  unmounted() {
    this.running = false;
  },
  methods: {

    async spin() {
      if (this.ran === true) return;
      this.ran = true;
      this.code = null;

      const { number } = (await this.axios(`${API}/request-number`)).data;
      const letters = (`${number}`).split('').map((x) => x * 1);
      console.log('>>>', number);

      for (let i = 0; i < 3; i++) {
        const target = (mod(-letters[i] + 7, 10) + 30) * sectionHeight;
        animate({
          timing: easeInOutCubic,
          draw: (p) => { this.offset[i] = p * target; },
          duration: 4000,
        });
      }

      await animate({ duration: 4300 });
      this.code = number;
      setTimeout(() => { this.ran = false; }, 5000);
    },

    updateLoop() {
      if (!this.running) return;
      requestAnimationFrame(this.updateLoop);

      // update spool positions
      for (let i = 0; i < this.offset.length; i++) {
        for (let j = 0; j < sections; j++) {
          let position = (j - Math.floor(sections / 2)) * sectionHeight;
          position += this.offset[i];
          position = mod(position, totalHeight) - sectionHeight * 2;
          this.spools[i][j].setAttribute('transform', `translate(0 ${position})`);
        }
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.box {
  font-size: 4rem;
  font-weight: bold;
  fill: #003A60;
}

.backdrop {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--sand-light);
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 80vw;
  background: #eceeef;
  border: 3px solid var(--blue);
  border-radius: 5px;
  padding: 1.5rem;
  max-height: 80vh;
  overflow: auto;

  .close {
    font-size: 50px;
    line-height: 0.5;
    padding: 10px;
    margin-top: -10px;
    margin-right: -10px;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}
</style>
